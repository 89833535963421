[data-component='ConsolePage'] {
  &.mobile-optimized {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1a1a1a;
    color: #ffffff;
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;

    .waveform-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      
      canvas {
        width: 100%;
        height: 40%;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    .controls {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.05);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .logo-container {
      text-align: center;
      margin: 25px auto;
    }

    .company-logo {
      height: 50px;
      width: auto;
    }
    
  }

  // Styles for the Toggle component
  [data-component='Toggle'] {
    background-color: #333;
    border-radius: 20px;
    overflow: hidden;

    .label {
      padding: 10px 20px;
      color: #fff;
      transition: color 0.3s ease;
    }

    .toggle-background {
      background-color: #0099ff;
    }

    &[data-enabled='true'] {
      .label.right {
        color: #fff;
      }
      .label.left {
        color: #666;
      }
    }

    &[data-enabled='false'] {
      .label.left {
        color: #fff;
      }
      .label.right {
        color: #666;
      }
    }
  }

  // Styles for the Button component
  [data-component='Button'] {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.3s ease;

    &.button-style-action {
      background-color: #0099ff;
      color: #fff;

      &:hover:not([disabled]) {
        background-color: #007acc;
      }
    }

    &.button-style-regular {
      background-color: #333;
      color: #fff;

      &:hover:not([disabled]) {
        background-color: #444;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  // Media query for larger screens
  @media (min-width: 768px) {
    &.mobile-optimized {
      font-size: 16px;

      .waveform-container {
        flex-direction: row;
        align-items: center;

        canvas {
          width: 45%;
          height: 60%;
          margin-bottom: 0;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .controls {
        padding: 30px;
      }
    }

    [data-component='Button'] {
      font-size: 16px;
      padding: 12px 24px;
    }
  }
}
