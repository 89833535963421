html,
body {
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #18181b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

img .logo {
  max-width: 100%;
  height: auto;
}   

/* - Added by API, seems fine to me but throwing up an error
@media (max-width: 768px) {
  html, body, #root {
    height: 100%;
    overflow: hidden;
  }*/